import React from "react";

export default ({ name, email, phone, role, imageName }) => (
  <div className="w-full  md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 pb-5 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <img className="mb-1" src={`/images/${imageName}.jpg`} alt={name}></img>
      <p className="w-full text-gray-600 text-xs md:text-sm px-6">{role}</p>
      <div className="w-full font-bold text-xl text-gray-800 px-6"> {name}</div>
      <div className="mt-4">
        <a
          className="w-full text-gray-600 text-xs md:text-sm px-6"
          href={`mailto:${email}`}
        >
          {email}
        </a>
        <p className="w-full text-gray-600 text-xs md:text-sm px-6">{phone}</p>
      </div>
    </div>
  </div>
);
