import React from "react";
import Layout from "../../components/layout/Layout.js";
import SEO from "../../components/SEO.js";
import Header from "../../components/layout/Header.js";
import Title from "../../components/layout/Title.js";
import SiteTitleQuery from "../../components/SiteTitleQuery.js";
import AllCoworkers from "../../components/coworkers/AllCoworkers.js";

function Coworkers() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Medarbetare"
            keywords={[
              `medarbetare`,
              `kundansvarig`,
              `projektledare`,
              `IT`,
              `administration`,
              `CAD-Ingenjör`,
              `fältarbete`,
              `utvecklare`,
              `systemutvecklare`
            ]}
          />
          <Header data={data} />
          <Title title="Medarbetare" />
          <AllCoworkers />
        </Layout>
      )}
    />
  );
}

export default Coworkers;
