import React from "react";

function shouldUseWebm(canPlayWebm) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (!isMobile) {
    const canPlayWebm = (function () {
      let element = document.createElement("video");
      try {
        if (!!element.canPlayType) {
          const canPlayRes = element
            .canPlayType('video/webm; codecs="vp8, vorbis"')
            .replace(/^no$/, "");
          return canPlayRes === "maybe" || canPlayRes === "probably";
        }
      } catch (e) { }
      // default till false
      return false;
    })();
    if (!canPlayWebm) {
      console.warning("no support for webm in your browser");
    }
    return canPlayWebm;
  }
  return false;
}

const shouldRenderVideo =
  typeof window !== "undefined" ? shouldUseWebm() : false;

export default ({ name, email, phone, role, imageName }) => (
  <div className="w-full  md:w-1/3 p-6 flex flex-col ">
    <div className="flex-1 pb-5 bg-white rounded-t rounded-b-none overflow-hidden shadow"> {/* flex-grow flex-shrink */}
      {shouldRenderVideo ? (
        <video autoPlay loop muted className="w-full">
          <source src={`/images/${imageName}.webm`} type="video/webm" alt={name} title={name} />
        </video>
      ) : (
        <img className="mb-3" src={`/images/${imageName}.jpg`} alt={name}></img>
      )}
      <p className="w-full text-gray-600 text-xs md:text-sm px-6">{role}</p>
      <div className="w-full font-bold text-xl text-gray-800 px-6"> {name}</div>
      <div className="mt-4">
        <a
          className="w-full text-gray-600 text-xs md:text-sm px-6"
          href={`mailto:${email}`}
        >
          {email}
        </a>
        <p className="w-full text-gray-600 text-xs md:text-sm px-6">{phone}</p>
      </div>
    </div>
  </div>
);

